import { HomeOutlined } from '@ant-design/icons';
import FinanceReports from '../domain/Reports/FinanceReports';
import FinanceReport from '../domain/Reports/FinanceReports/FinanceReport';
import CurrentAccount from '../domain/Reports/FinanceReports/CurrentAccount';
import ScholarshipReport from '../domain/Reports/FinanceReports/ScholarshipReports';

const routes = [
  // PRIVATE

  {
    path: '/',
    component: FinanceReports,
    exact: true,
    title: 'Reportes financieros',
    breadcrumb: 'Reports',
    icon: <HomeOutlined />,
    roleAccess: 'all',
    showInMenu: true,
  },

  {
    path: '/reportes/financiero',
    component: FinanceReport,
    exact: true,
    title: 'Reportes financieros',
    breadcrumb: 'Reports',
    icon: <HomeOutlined />,
    roleAccess: 'all',
    showInMenu: true,
  },
  {
    path: '/reportes/cuenta-corriente',
    component: CurrentAccount,
    exact: true,
    title: 'Reportes Cuenta Corriente',
    breadcrumb: 'Reports',
    icon: <HomeOutlined />,
    roleAccess: 'all',
    showInMenu: true,
  },
  {
    path: '/reportes/becas-repartos',
    component: ScholarshipReport,
    exact: true,
    title: 'Reportes Cuenta Corriente',
    breadcrumb: 'Reports',
    icon: <HomeOutlined />,
    roleAccess: 'all',
    showInMenu: true,
  },
];

export default routes;

import { BarChartOutlined, DollarOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';

import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AppContext } from '../../../app/AppContext';
import { GraduationGatIcon, HandCurrencyIcon } from '../../../assets/icons';
import ChartCurrencyIcon from '../../../assets/icons/ChartCurrency';
import OutlineArrowCircleLeftIcon from '../../../assets/icons/OutlineArrowCircleLeft';
import CardImageText from '../../../components/CardImageText';
import IconDot from '../../../components/IconDot';
import SummaryItem from '../../../components/SummaryItem';

const FinanceReports = () => {
  const { financeData, isDatasetLoading, functions = [] } = useContext(AppContext);

  const router = useHistory();

  const reportList = [
    {
      key: 'INVERSION_CAPACITACION',
      component: (
        <CardImageText
          title="Inversión Capacitación"
          buttonText="Ver reporte"
          onClick={() => {
            router.push('/reportes/financiero');
          }}
          imageComponent={
            <IconDot icon={<ChartCurrencyIcon />} size={34} position={{ top: 5, left: 5 }} />
          }
          text={
            <ul style={{ paddingLeft: '16px' }}>
              <li> Costo Empresa</li>
              <li> Costo franquicia</li>
              <li> Costo por hora</li>
            </ul>
          }
        />
      ),
    },
    {
      key: 'CUENTA_CORRIENTE',
      component: (
        <CardImageText
          title="Cuenta Corriente"
          buttonText="Ver reporte"
          imageComponent={<IconDot icon={<HandCurrencyIcon />} size={34} />}
          onClick={() => {
            router.push('/reportes/cuenta-corriente');
          }}
          text={
            <ul style={{ paddingLeft: '16px' }}>
              <li> Aportes</li>
              <li> Excedentes</li>
              <li> Saldo disponible</li>
            </ul>
          }
        />
      ),
    },
    {
      key: 'BECAS_Y_REPARTO',
      component: (
        <CardImageText
          title="Becas y Repartos"
          buttonText="Ver reporte"
          onClick={() => {
            router.push('/reportes/becas-repartos');
          }}
          imageComponent={
            <IconDot icon={<GraduationGatIcon />} size={34} position={{ left: 23 }} />
          }
          text={
            <ul style={{ paddingLeft: '16px' }}>
              <li> Monto de becas</li>
              <li> Monto de repartos</li>
            </ul>
          }
        />
      ),
    },
  ];

  return (
    <Row gutter={12} style={{ width: '100%' }}>
      <Col span={24}>
        <div
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '30px' }}
        >
          <OutlineArrowCircleLeftIcon style={{ marginRight: '6px' }} />
          <Link
            to="/"
            style={{ textAlign: 'center' }}
            onClick={() => {
              window.top.postMessage('goBackHome', '*');
            }}
          >
            Volver atrás
          </Link>
        </div>
      </Col>

      <Col xs={24} lg={{ span: 9 }}>
        <h2>
          {' '}
          <strong>Información Financiera</strong>
        </h2>
        <p>
          Revisa en línea tu uso y saldos de aportes, excedentes, costos de capacitación, becas y
          reparto.
        </p>

        <div className="summary">
          <h4>Resumen {financeData?.year}</h4>
          <Row>
            <Col span={24}>
              <SummaryItem
                value={`$${financeData.enterpriseCost}`}
                icon={<DollarOutlined style={{ color: '#EDA645' }} />}
                description="Costo Empresa"
                loading={isDatasetLoading}
              />
            </Col>
            <Col span={24}>
              <SummaryItem
                value={`$${financeData.taxExemption}`}
                icon={<DollarOutlined style={{ color: '#EDA645' }} />}
                description="Costo Franquicia"
                loading={isDatasetLoading}
              />
            </Col>
            <Col span={24}>
              <SummaryItem
                value={`$${financeData.totalInvestment}`}
                icon={<BarChartOutlined style={{ color: '#EDA645' }} />}
                description="Inversión Anual"
                loading={isDatasetLoading}
              />
            </Col>
          </Row>
        </div>
      </Col>

      <Col xs={24} lg={{ span: 15 }}>
        <Row gutter={6}>
          {functions.map((f) => {
            const report = reportList.find((r) => r.key === f.key);

            return (
              <Col xs={24} md={{ span: 12 }} lg={{ span: 8 }} style={{ marginBottom: '75px' }}>
                {report.component}
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};

export default FinanceReports;

import request from '../../utils/request';

const getCapacitance = () => {
  return request({
    url: '/api/report/capacitance',
    method: 'POST',
    private: true,
  });
};
const getScholarship = () => {
  return request({
    url: '/api/report/scholarship',
    method: 'POST',
    private: true,
  });
};
const getFinance = () => {
  return request({
    url: '/api/report/finance',
    method: 'POST',
    private: true,
  });
};
const getCurrentAccount = () => {
  return request({
    url: '/api/report/current-account',
    method: 'POST',
    private: true,
  });
};

const ReportService = {
  getCapacitance,
  getFinance,
  getCurrentAccount,
  getScholarship,
};

export default ReportService;

import request from '../../utils/request';

const getAll = ({ params }) => {
  return request({
    url: '/api/user',
    method: 'GET',
    private: true,
    params,
  });
};
const findOne = ({ id }) => {
  return request({
    url: `/api/user/${id}`,
    method: 'GET',
    private: true,
  });
};

const getSystemFunctions = ({ system }) => {
  return request({
    /** Local */
    // url: `http://localhost:1337/api/get-user-systems/${system}`,
    /** Production */
    url: `https://apisso.ccc.cl/api/get-user-systems/${system}`,
    method: 'GET',
    private: true,
    profileAPI: true,
  });
};

const UserService = {
  getAll,
  findOne,
  getSystemFunctions,
};

export default UserService;

export default function ChartCurrencySVG() {
  return (
    <svg width="55" height="46" viewBox="0 0 55 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.00634569 44.2116C0.304593 43.8129 0.702257 43.6881 1.19405 43.6987C2.31829 43.722 3.4436 43.7061 4.64611 43.7061V43.0652C4.64611 39.4249 4.64611 35.7845 4.64611 32.1442C4.64611 31.2685 4.89147 31.0284 5.7767 31.0274C8.06326 31.0274 10.3509 31.0274 12.6375 31.0274C13.5904 31.0274 13.8294 31.2622 13.8294 32.1961C13.8294 35.8184 13.8294 39.4418 13.8294 43.0641C13.8294 43.2545 13.8294 43.4449 13.8294 43.6701H16.5739C16.5739 43.4406 16.5739 43.2344 16.5739 43.0282C16.5739 37.8226 16.5739 32.617 16.5739 27.4125C16.5739 26.4744 16.7865 26.2523 17.7045 26.2512C19.9911 26.2491 22.2787 26.2491 24.5652 26.2512C25.4737 26.2512 25.7212 26.4987 25.7212 27.4103C25.7233 32.5979 25.7212 37.7856 25.7223 42.9742C25.7223 43.1974 25.7223 43.4216 25.7223 43.6754H28.4224V43.062C28.4224 38.2964 28.4224 33.5308 28.4224 28.7651C28.4224 27.6853 28.6519 27.4516 29.7095 27.4516C31.9612 27.4516 34.2128 27.4505 36.4645 27.4516C37.3825 27.4516 37.6511 27.7213 37.6511 28.6541C37.6532 33.4377 37.6511 38.2202 37.6511 43.0038C37.6511 43.2122 37.6511 43.4205 37.6511 43.667H40.3502V42.9774C40.3502 35.5032 40.3491 28.029 40.3554 20.5559C40.3554 20.281 40.348 19.9404 40.4972 19.749C40.6653 19.5332 41.0048 19.3354 41.2713 19.3323C43.7166 19.3016 46.1618 19.309 48.607 19.3175C49.2553 19.3196 49.5049 19.6358 49.5049 20.3973C49.5049 27.9413 49.5049 35.4863 49.5049 43.0303C49.5049 43.2365 49.5049 43.4438 49.5049 43.7071C50.7285 43.7071 51.9014 43.7008 53.0743 43.7093C53.766 43.7146 54.0918 44.0223 54.0399 44.6008C53.9998 45.0461 53.7459 45.2999 53.3081 45.3655C53.1875 45.3835 53.0627 45.3718 52.939 45.3718C35.6628 45.3718 18.3877 45.3708 1.11155 45.3803C0.627166 45.3803 0.25277 45.2692 0 44.8462V44.2127L0.00634569 44.2116ZM42.0487 21.0044V43.6712H47.8032V21.0044H42.0487ZM24.0301 43.6765V27.9328H18.265V43.6765H24.0301ZM35.8817 43.6849V29.1522H30.122V43.6849H35.8817ZM6.33512 32.7185V43.6765H12.1012V32.7185H6.33512Z"
        fill="#414046"
      />
      <path
        d="M45.9224 0C46.3242 0.106819 46.7283 0.20412 47.1259 0.322573C50.4955 1.32202 52.6424 4.52871 52.3484 8.11826C52.0713 11.5121 49.2591 14.392 45.843 14.7791C41.8378 15.2339 38.3678 12.6861 37.618 8.74014C36.8586 4.74341 39.6221 0.779463 43.6337 0.105762C43.731 0.0898973 43.8219 0.0359589 43.9161 0C44.5845 0 45.2529 0 45.9213 0L45.9224 0ZM39.1388 7.40754C39.1462 10.6269 41.7204 13.1959 44.9356 13.1916C48.1465 13.1874 50.7017 10.6407 50.6996 7.4435C50.6985 4.21671 48.137 1.66257 44.907 1.66786C41.6919 1.67315 39.1303 4.22094 39.1378 7.40648L39.1388 7.40754Z"
        fill="#414046"
      />
      <path
        d="M32.1428 24.0175C31.8593 23.9266 31.6563 23.8769 31.4649 23.7976C28.1598 22.4301 24.8526 21.0647 21.5539 19.6813C21.2081 19.5364 20.9691 19.5459 20.6539 19.7733C17.2463 22.2259 13.8249 24.6606 10.4067 27.0994C10.2491 27.2115 10.0957 27.3321 9.92651 27.4231C9.52885 27.6378 9.1682 27.5658 8.90168 27.2041C8.63939 26.8488 8.67218 26.4765 8.96514 26.1465C9.06773 26.0302 9.20839 25.9466 9.33636 25.8546C12.9534 23.2709 16.5747 20.6935 20.1822 18.096C20.6613 17.7512 21.0738 17.6814 21.6343 17.9193C24.8928 19.3027 28.1683 20.6438 31.4321 22.0165C31.7821 22.1635 32.019 22.1477 32.3258 21.9013C34.8725 19.8516 37.4372 17.8241 39.9903 15.7829C40.3562 15.49 40.7729 15.3377 41.1357 15.6381C41.3462 15.8115 41.453 16.1986 41.4646 16.4958C41.472 16.6872 41.2616 16.9368 41.0828 17.0785C38.3499 19.2604 35.6075 21.4317 32.862 23.5966C32.6378 23.7732 32.3659 23.8875 32.1428 24.0154V24.0175Z"
        fill="#414046"
      />
      <path
        d="M42.6432 9.55296C43.338 9.6841 43.98 9.84592 44.6326 9.91466C45.154 9.96966 45.65 9.62699 45.7092 9.26211C45.7981 8.71744 45.4935 8.41073 45.0345 8.20661C44.6664 8.04374 44.2952 7.88827 43.9356 7.70848C43.1508 7.31504 42.6696 6.67413 42.6739 5.79525C42.6781 4.88358 43.0588 4.14008 43.9314 3.7054C44.0741 3.63454 44.1799 3.41879 44.2286 3.24957C44.2793 3.074 44.2402 2.87306 44.2402 2.64355H45.4691V3.52138C45.8806 3.6282 46.2475 3.72338 46.6727 3.83337C46.6262 4.25219 46.5818 4.6636 46.5299 5.13741C46.1502 5.04328 45.8065 4.94281 45.4564 4.87407C44.6992 4.72494 44.1672 5.08982 44.2476 5.75189C44.2772 5.99937 44.4877 6.29339 44.7013 6.43405C45.0916 6.69105 45.5474 6.84758 45.9747 7.04958C46.807 7.44302 47.3136 8.07547 47.3094 9.01781C47.3052 9.96226 46.8588 10.6645 45.9874 11.0527C45.7219 11.1711 45.6003 11.3118 45.6362 11.6037C45.6595 11.7919 45.6405 11.9855 45.6405 12.2086H44.4697C44.4475 12.1727 44.4179 12.1452 44.4158 12.1166C44.3706 11.5667 44.0713 11.2233 43.5178 11.0865C43.2132 11.0114 42.9076 10.9395 42.5205 10.8454C42.5618 10.4117 42.6051 9.95379 42.6421 9.5519L42.6432 9.55296Z"
        fill="#414046"
      />
    </svg>
  );
}
